<template>
  <main class="home">
<!--    <WhatsappIconComponent-->
<!--        :phone=whatsapp_phone-->
<!--    ></WhatsappIconComponent>-->
    <CarouselQE
        :info_carousel="info_carousel"
        @banner_buy="$router.push('/menudigital/')"
    >
    </CarouselQE>
    <section class="carta">
      <div class="carta__container section">
        <h2 class="carta__subtitle">{{ $t('home.txt_nuestra_carta') }}</h2>
        <section class="cartas">
          <CategoriasCarta
              :style="`background-image: url(${item.img})`"
              v-for="(item, index) in listCartas"
              :category=item
              :key="index"
              @click="loadCard(item.id)"
          >
          </CategoriasCarta>
        </section>
        <ButtonPrimary
            type="button"
            @click="loadCard(null)"
        >
          {{ $t('buttons.txt_ordenar_ahora') }}
        </ButtonPrimary>
      </div>
    </section>
    <section class="about">
      <div class="about__container section grid">
        <div class="about__image-1"></div>
        <div class="about__image-2"></div>
        <div class="about__image-3"></div>
        <div class="about__image-4"></div>
        <div class="about__image-5"></div>
        <div class="about__image-6"></div>
        <div class="about__image-7"></div>
      </div>
    </section>
    <section class="register">
      <div class="register__container section">
        <h3 class="register__subtitle">{{ $t('home.txt_quieres_estar_al_tanto_de') }} <br>{{ $t('home.txt_registrate') }}</h3>
        <ButtonPrimary
            type="button"
            @click="registerNowClicked"
        >
          {{ $t('buttons.txt_quiero_registrarme') }}
        </ButtonPrimary>
      </div>
    </section>
    <transition name="slide-fade">
      <LocalModalSelect
          v-if="showModalLocal"
          :ok="ModalLocal.ok"
          :no="ModalLocal.no"
          :message="ModalLocal.message"
          @ok="goToMenu"
          @no="showModalLocal=false"
      ></LocalModalSelect>
    </transition>
    <transition name="slide-fade">
      <ModalLocalSchedules
          v-if="showModalLocalSchedules"
          :ok="ModalLocal.ok"
          :no="ModalLocal.no"
          @ok="goToMenu"
          @no="showModalLocalSchedules=false"
      >
      </ModalLocalSchedules>
    </transition>
    <transition name="slide-fade">
    <ModalPhoneInput
        v-if="showModalPhoneInput"
        @closePhoneModal="showModalPhoneInput=false"
    >
    </ModalPhoneInput>
    </transition>
  </main>
  <transition name="slide-fade">
    <LoadingBigComponent
      v-if="loading">

    </LoadingBigComponent>
  </transition>

</template>

<script>

import LocalServices from "@/services/local-services"
import {mapMutations, mapState} from "vuex";
// import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import CategoriasCarta from "@/components/Recursos/CategoriasCarta.vue";
import CarouselQE from "@/components/CarouselQE.vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
import LocalModalSelect from "@/components/MODALS/LocalModalSelect";
import ModalLocalSchedules from "@/components/MODALS/ModalLocalSchedules"
import ModalPhoneInput from "@/components/MODALS/ModalPhoneInput";
import LoadingBigComponent from "@/components/Recursos/LoadingBigComponent";
import {useRoute} from "vue-router";
import {useHead} from "@unhead/vue";

export default {
  name: 'Home',
  components: {
    LoadingBigComponent,
    ModalLocalSchedules,
    LocalModalSelect,
    ButtonPrimary,
    CarouselQE,
    CategoriasCarta,
    // WhatsappIconComponent,
    ModalPhoneInput,
},
  setup() {
    const route = useRoute();
    const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;
    useHead({
      title: `Inicio`,
      link: [
        { rel: 'canonical', href: canonicalUrl }
      ],
      meta: [
        {
          name: "description",
          content:
              `Bienvenido a ${process.env.VUE_APP_BUSINESS_COMERCIAL_NAME}`,
        },
        { name: "author", content: "Quick eat!" },
        { name: "copyright", content: "Quick eat!" },

        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },
      ],
    });
  },
  async created () {
    this.is_ssr = typeof window === 'undefined';
  },
  async mounted() {
    this.getWhatsappPhone()

    let url_string = window.location.href;
    let url = new URL(url_string);

    let temp_track = url.searchParams.get("fast_register")
    if (temp_track !== undefined && temp_track !== null) {
      this.setShowModalLoginRegister(true)
    }
    await this.getLocals()
    await this.getCategories()
    if (!this.is_ssr) {
      window.scrollTo(0, 0)
    }
    this.loading = false
  },
  data () {
    return {
      listCartas: [],
      homeLocalList: [],
      info_carousel: [
        {
          imgMobile: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/banner_1_mobile.webp',
          imgDesktop: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/banner_1_desktop.webp',
        },
        {
          imgMobile: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/banner_2_mobile.webp',
          imgDesktop: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/banner_2_desktop.webp',
        },
        {
          imgMobile: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/banner_3_mobile.webp',
          imgDesktop: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/banner_3_desktop.webp',
        },
      ],
      showModalLocal: false,
      showModalLocalSchedules: false,
      loading: true,
      ModalLocal: {
        ok: '',
        no: '',
        message: ''
      },
      category_home: 0,
      whatsapp_phone: '',
      showModalPhoneInput: false,
      is_ssr: false,
    }
  },

  computed: {
    ...mapState(['isAuthenticated', 'user', 'listLocals', 'orderLocal', 'isSelfService'])
  },
  methods: {
    ...mapMutations(['setUser', 'setAuthenticatedTrue', 'setShowModalLoginRegister', 'setOrderLocal']),
    registerNowClicked() {
      if (!this.isAuthenticated) {
        this.setShowModalLoginRegister(true)
        return
      }
      this.$toast.info(this.$t('home.txt_ya_se_encuentra_registrado'))
    },
    getWhatsappPhone() {
      this.whatsapp_phone = process.env.VUE_APP_WHATSAPP_PHONE
    },
    async getLocals() {

      let response = await LocalServices.listLocalesCercanos()
      if (response.data.success) {
        response.data.data = response.data.data.filter(item => {
          if (!item.deleted) {
            return item
          }
        })
        for (let i = 0; i < response.data.data.length; i++) {
          const dayLists = [[], [], [], [], [], [], []];

          for (let g = 0; g < response.data.data[i].schedules.length; g++) {
            const day = parseInt(response.data.data[i].schedules[g].day, 10);
            dayLists[day].push(response.data.data[i].schedules[g]);
          }
          for (let z=0; z < dayLists.length; z++) {
            if (dayLists[z].length > 0) {
              dayLists[z] = dayLists[z].sort(function (a, b) {
                if (a.time_init > b.time_init) {
                  return 1;
                }
                if (a.time_init < b.time_init) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
            }
          }
          response.data.data[i].day_list = dayLists
        }
        this.homeLocalList = response.data.data
        if (this.orderLocal == null || !this.isSelfService) {
          this.setOrderLocal(this.homeLocalList.find(local => local.id === parseInt(process.env.VUE_APP_FIRST_LOCAL_ID)))
        }

        let matchingLocal = this.homeLocalList.find(local => local.id === this.orderLocal.id);

        if (matchingLocal) {
          this.setOrderLocal(matchingLocal);
        } else {
          if (this.homeLocalList.length > 0) {
            this.setOrderLocal(this.homeLocalList[0])
          } else {
            this.setOrderLocal(null)
          }
        }

      }

    },

    loadCard (category_id) {
      this.category_home = category_id
      if (!this.orderLocal.is_available_local) {
        this.ModalLocal.message = this.$t('home.txt_local_saturado_lo_sentimos');
        this.ModalLocal.ok = this.$t('dropdownaddress.txt_ver_carta');
        this.ModalLocal.no = this.$t('dropdownaddress.txt_regresar');
        this.showModalLocal = true
        return
      }
      if (!this.orderLocal.is_in_area) {
        this.ModalLocal.ok = this.$t('dropdownaddress.txt_ver_carta');
        this.ModalLocal.no = this.$t('dropdownaddress.txt_regresar');
        this.showModalLocalSchedules = true
        return
      }
      if (this.orderLocal.is_available_message) {
        this.ModalLocal.message = this.orderLocal.message
        this.ModalLocal.ok = this.$t('dropdownaddress.txt_si');
        this.ModalLocal.no = this.$t('dropdownaddress.txt_no');
        this.showModalLocal = true
        return
      }
      this.goToMenu()
    },

    goToMenu() {
      if (this.category_home !== null) {
        this.$router.push(`/menudigital/${this.category_home}`)
        return
      }
      this.$router.push({name: 'menudigital'})
    },
    async getCategories () {
      try {
        let response = await LocalServices.listCategoriesOfLocal(this.orderLocal.id)
        if (response.data.success) {
          let promo = response.data.data.filter(carta => {
            if (!carta.deleted && carta.is_promotion) {
              return carta
            }
          })
          promo.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          let normal = response.data.data.filter(carta => {
            if (!carta.deleted && !carta.is_promotion) {
              return carta
            }
          })
          normal.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          this.listCartas = promo.concat(normal)
        }
      } catch (error) {
        console.log(error)
      }
    },
    openLink (to) {
      switch (to) {
        case 'android':
          window.open('https://play.google.com/store/apps/details?id=cquickeat.elbarrilitoexpress', '_blank')
          break;
        case 'iOS':
          window.open('https://apps.apple.com/es/app/barrilito-express/id1524896987', '_blank')
          break
      }
    },
  }
}
</script>

<style scoped>

  .fondo{
    display: none;
  }

  /* ------------ INICIO DE SECCIÓN CARTA ------------ */

  .carta{
    background-color: #0D0D0D;
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/carta-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .carta__container{
    max-width: 1400px;
    margin-inline: auto;
  }

  .carta__subtitle{
    text-align: center;
    margin-bottom: 30px;
    color: var(--text-white);
    font-family: var(--headings-font-family);
  }

  .carta button{
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .cartas{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  /* ------------ FIN DE SECCIÓN CARTA ------------ */

  /* ------------ INICIO DE SECCIÓN APP ------------ */

  .app{
    background-color: #2A2A2A;
    color: var(--text-white);
  }

  .app__subtitle{ text-align: center; }

  .app__information-image{
    display: flex;
    gap: 20px;
  }

  .app__information{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 70%;
  }

  .app__subtitle-2{ display: none; }

  .app__description-2{ display: none}

  .app__description{ font-size: 16px; }

  .app__store{ display: none; }

  .app__store img{
    width: 50%;
    cursor: pointer;
  }

  .app__image{
    width: 30%;
    text-align: right;
  }

  /* ------------ FIN DE SECCIÓN APP ------------ */

  /* ------------ INICIO DE ASIDE APP ------------ */
  .aside{ background-color: #0D0D0D; }

  .aside img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* ------------ FIN DE ASIDE APP ------------ */

  /* ------------ INICO DE SECCIÓN ABOUT ------------ */

  .about{ background-color: #0D0D0D; }

  .about__container{
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 350px 175px 175px;
    gap: 10px;
    max-width: 1400px;
    margin-inline: auto;
  }

  [class^="about__image"]{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
  }

  .about__image-1{
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-6.webp");
  }

  .about__image-2{
    grid-column: 1 / -1;
    grid-row: 3 / 4;
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-7.webp");
    background-position: 0% 65%;
  }

  .about__image-3{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-1.webp");
  }

  .about__image-4{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-2.webp");
  }

  /* ------------ FIN DE SECCIÓN ABOUT ------------ */

  /* ------------ INICIO DE SECCIÓN REGISTER ------------ */

  .register{
    display: none;
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-5.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 0px 250px rgba(0, 0, 0, .6) inset;
    margin-bottom: 50px;
  }

  .register__container{
    text-align: center;
  }

  .register__subtitle{
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    color: var(--text-white);
  }

  .register__container button{
    margin-left: auto;
    margin-right: auto;
  }

  /* ------------ FIN DE SECCIÓN REGISTER ------------ */

  @media only screen and (min-width: 768px){
    .about__container{
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 200px 425px;
      gap: 20px;
    }
    .about__image-1{
      grid-column: 1 / 4;
      grid-row: 1 / 2;
    }
    .about__image-2{
      grid-column: 4 / -1;
      grid-row: 1 / 2;
    }
    .about__image-3{
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }
    .about__image-4{
      grid-column: 3 / 5;
      grid-row: 2 / 3;
    }
    .about__image-5{
      grid-column: 5 / -1;
      grid-row: 2 / 3;
      background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-3.webp");
    }
    .register__subtitle{
      width: 500px;
      margin-left: auto;
      margin-right: auto;
      font-size: 24px;
    }
  }

  @media only screen and (min-width: 1024px){
    .app__subtitle{ display: none }
    .app__information{ gap: 50px }
    .app__subtitle-2{
      display: block;
      font-size: 38px;
      text-transform: uppercase;
    }
    .app__description{ display: none; }
    .app__description-2{
      display: block;
      font-size: 28px;
    }
    .app__store{
      display: flex;
      gap: 30px;
    }
    .app__store img{ width: 200px; }
    .about__container{
      grid-template-rows: 500px 250px 250px 475px;
      gap: 40px;
    }
    .about__image-1{
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-1.webp");
      grid-column: 1 / 3;
    }
    .about__image-2{
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-2.webp");
      grid-column: 3 / 5;
    }
    .about__image-3{
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-3.webp");
      grid-column: 5 / -1;
      grid-row: 1 / 2;
    }
    .about__image-4{
      background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-4.webp");
      grid-column: 1 / 4;
      grid-row: 2 / 4;
    }
    .about__image-5{
      background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-5.webp");
      grid-column: 4 / -1;
      grid-row: 2 / 3;
    }
    .about__image-6{
      background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-6.webp");
      grid-column: 4 / -1;
      grid-row: 3 / 4;
      display: block;
      background-attachment: local;
    }
    .about__image-7{
      background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Tostado+Caf%C3%A9/ux_web_files/about-7.webp");
      background-position: 0% 70%;
      grid-column: 1 / -1;
      grid-row: 4 / -1;
      display: block;
    }
    .register__subtitle{
      width: 600px;
      font-size: 28px;
    }
  }

  @media only screen and (min-width: 1440px){
    .cartas{ gap: 30px; }
    .carta__subtitle{ margin-bottom: 50px; }
    .carta button{
      margin-top: 40px;
      font-size: 18px;
      text-transform: uppercase;
    }
    .register__subtitle{
      width: 620px;
      font-size: 38px;
    }
    .register button{ font-size: 18px; }
  }
</style>